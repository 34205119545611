import React from "react";
import idHelper from "../../02-tools/id-helper/idHelper.js";
import Insight from "../../02-tools/insight/insight.js";

export default class Footer extends React.Component {
    constructor(props) {
        super(props);
    }

    linkClickHandler = (event) => {
        event.preventDefault();
        const linkText = event.target.textContent;
        const sectionTitle = event.target.closest('.polno-site-navigation__section-content').querySelector('.polno-site-navigation__section-title').textContent;
        const linkUrl = event.target.href ? event.target.href : event.target.closest('a').href;

        Insight.track('matomo', {
            isEvent: true,
            category: 'Navigasjon',
            action: `Klikk link i meny - ${linkText} (${sectionTitle})`,
            name: `${document.title} (${location.pathname})`,
            value: 0,
        });

        location.href = linkUrl;
    }

    languageLinkClickHandler = (event) => {
        event.preventDefault();
        const linkText = event.target.textContent;
        const linkUrl = event.target.href ? event.target.href : event.target.closest('a').href;

        Insight.track('matomo', {
            isEvent: true,
            category: 'Navigasjon',
            action: `Bytt språk i meny - ${linkText}`,
            name: `${document.title} (${location.pathname})`,
            value: 0,
        });

        location.href = linkUrl;
    }

    renderMenuItems = (items) => {
        if (items) {
            return items?.map((item, index) => {
                return (
                    <li className="polno-site-navigation__section-item" key={index}>
                        <a onClick={this.linkClickHandler} className="polno-site-navigation__link" href={item.path} tabIndex="0">
                            <span className="polno-site-navigation__link-underline">{item.title}</span>
                        </a>
                    </li>
                )
            })
        }
    }

    renderMenuSections = (sections) => {
        if (sections) {
            return sections?.map((section, index) => {
                let sectionUrlArr = section.path.split("/");
                let localizationKey = sectionUrlArr[sectionUrlArr.length - 2];
                let sectionLinkText = this.props.LocalizationStrings?.toplevellinktext?.[localizationKey]; // Localization key is last part of section url

                return (
                    <li className="polno-site-navigation__section-content" key={index}>
                        <h2 className="polno-site-navigation__section-title" id={idHelper.createId(section.title)}>{section.title}</h2>
                        <ul className="polno-site-navigation__section-items list--reset-style">
                            {this.renderMenuItems(section.items)}
                            <li className="polno-site-navigation__section-item polno-site-navigation__section-item--top-level">
                                <a onClick={this.linkClickHandler} className="polno-site-navigation__link" href={section.path} tabIndex="0">
                                    <span className="polno-site-navigation__link-underline">{sectionLinkText}</span>
                                    <svg className="polno-site-navigation__top-link-arrow" viewBox="0 0 24 24" aria-hidden="true">
                                        <path fill="currentColor" d="M4,11V13H16L10.5,18.5L11.92,19.92L19.84,12L11.92,4.08L10.5,5.5L16,11H4Z"/>
                                    </svg>
                                </a>
                            </li>
                        </ul>
                    </li>
                )
            })
        }
    }

    renderMenuColumns = (columns) => {
        if (columns) {
            return columns?.map((column, index) => {
                return (
                    <div className="xsmall-12 large-4 columns" key={index}>
                        <ul className="polno-site-navigation__section list--reset-style-type">
                            {this.renderMenuSections(column.items)}
                        </ul>
                    </div>
                )
            })
        }
    }

    renderLanguageSelector = (languageData) => {
        const language = languageData
            ?.filter(x => x.languageId !== this.props.Language)
            .find(x => x.languageId === 'nb' || x.languageId === 'en');

        if (!language)
            return null;

        return (
            <li className="polno-site-navigation__section-item">
                <a onClick={this.languageLinkClickHandler}
                   className="polno-site-navigation__link polno-site-navigation__link--language"
                   href={language.path}
                   data-gtm-hook="language-switch-link">
                                    <span className="polno-site-navigation__link-underline"
                                          lang={language.languageId}>
                                        {this.props.LocalizationStrings?.languagelinktext ?? language.title}
                                    </span>
                    <svg className="polno-site-navigation__top-link-arrow" viewBox="0 0 24 24" aria-hidden="true">
                        <path fill="currentColor" d="M4,11V13H16L10.5,18.5L11.92,19.92L19.84,12L11.92,4.08L10.5,5.5L16,11H4Z"/>
                    </svg>
                </a>
            </li>
        )
    }

    render() {
        return (
            <nav className={`polno-site-navigation${this.props.ShowMenu ? " polno-site-navigation--open" : ""}`} id="site-navigation"
                 aria-expanded={this.props.ShowMenu} aria-hidden={!this.props.ShowMenu}>
                <div className="polno-site-navigation__content-wrapper">
                    <div className="polno-site-navigation__content">
                        <div className="row">
                            {this.renderMenuColumns(this.props.MenuData)}
                        </div>
                        <div className="row">
                            <div className="xsmall-12 columns">
                                <ul className="polno-site-navigation__section list--reset-style-type" data-gtm-component="language-switch">
                                    <li className="polno-site-navigation__section-content">
                                        <ul className="polno-site-navigation__section-items list--reset-style">
                                            {this.renderLanguageSelector(this.props.HeaderData?.languageUrls)}
                                        </ul>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </nav>
        )
    }
}
