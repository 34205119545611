/* eslint-disable react/prop-types */

import React from 'react';
import XHR from '../../02-tools/xhr/xhr';
import Insight from '../../02-tools/insight/insight';
import Keyboard from '../../02-tools/keyboard/keyboard';
import { debounce } from 'lodash';

export default class GlobalSearch extends React.Component {
  constructor(props) {
    super(props);

    this.inputRef = React.createRef();
        this.suggestionsRef = React.createRef();
        this.formRef = React.createRef();
        this.robotRef = React.createRef();
        this.actionsRef = React.createRef();

        this.state = {
            selectedRef: '',
            isExpanded: false,
            typedValue: "",
            isInteracted: false,
            suggestions: [],
            hideRobot: false
        };

        // Debounce method binding
        this.debouncedHandleInputChange = debounce(this.handleInputChange, 500);
  }

  get input() { return this.inputRef?.current; }
  get suggestions() { return this.suggestionsRef?.current; }
  get form() { return this.formRef?.current; }
  get robot() { return this.robotRef?.current; }
  get actions() { return this.actionsRef?.current; }

  componentDidMount({ props } = this) {
    if (!props.IsGlobal && props.IsInFocus) {
      this.input?.focus();
      this.setState({ selectedRef: this.inputRef });
    }

    if (props.Query) {
      const caretPos = props.Query.length;
      this.input?.setSelectionRange(caretPos, caretPos);

      if (this.input) {
        this.input.value = this.props.Query;
      }
    }

    this.robot && setTimeout(() => this.robot.tabIndex = -1, 2000);
  }

  componentDidUpdate(_, prevState) {
    if ((prevState.typedValue !== this.state.typedValue) && !this.state.typedValue) {
      setTimeout(() => this.clearList(), 200);
    }
  }

  clearList() {
    this.setState({ suggestions: [] });
  }

  handleSubmit() {
    const trackingAction =
      this.form.getAttribute('data-gtm-component') === 'search-global'
        ? 'Foreta førstegangssøk'
        : 'Trykke på søkefelt';

    Insight.track('matomo', {
      isEvent: true,
      category: 'Søk',
      action: trackingAction,
      name: `${document.title} (${location.pathname})`,
      value: 0,
    });

    if (this.input?.value && this.robot?.value === "") {
      this.setState({ hideRobot: true }, _ => this.form?.submit());
    }
  }

  onInputChange = (e) => {
    const value = e.target.value;
    this.setState({
        selectedRef: this.inputRef,
        isExpanded: true,
        typedValue: value,
        isInteracted: true
    });

    this.debouncedHandleInputChange(value);
};

handleInputChange = (val) => {
    return new Promise(resolve => {
        if (val.length >= 2 && /\S/.test(val)) { // Check for non-whitespace and length >= 2
            resolve(XHR.get('/api/find/Autocomplete/', val)
              .then(data => JSON.parse(data)
                .splice(0, 5) // Limit to first 5 results
                .filter((item, pos, self) => self.indexOf(item.trim()) === pos)
                .filter(item => item !== val)))
        } else {
            resolve([]);
        }
    }).then(suggestions => this.setState({ suggestions: suggestions }));
};

  handleInputKeyDown(e) {
    const key = e.code || e.keyCode || e.which;

    if (key === Keyboard.up) {
      e.preventDefault();

      const el = this.suggestions?.lastElementChild;
      el?.focus();
      this.input.value = el.textContent;

      this.setState({ selectedRef: el });
    } else if (key === Keyboard.down) {
      e.preventDefault();

      const el = this.suggestions?.firstElementChild;
      el?.focus();
      this.input.value = el.textContent;

      this.setState({ selectedRef: el });
    } else if (key === Keyboard.esc) {
      this.clearList();
    }
  }

  handleSuggestionKeyDown(e, { state } = this) {
    const key = e.code || e.keyCode || e.which;

    if (key === Keyboard.up) {
      e.preventDefault();

      if (state.selectedRef === this.suggestions?.firstElementChild) {
        this.input?.focus();
        this.input.value = state.typedValue;

        this.setState({ selectedRef: this.inputRef });
      } else {
        const el = state.selectedRef.previousElementSibling;
        el?.focus();
        this.input.value = el.textContent;

        this.setState({ selectedRef: el });
      }
    } else if (key === Keyboard.down) {
      e.preventDefault();

      if (state.selectedRef === this.suggestions?.lastElementChild) {
        this.input?.focus();
        this.input.value = state.typedValue;

        this.setState({ selectedRef: this.inputRef });
      } else {
        const el = state.selectedRef.nextElementSibling;
        el?.focus();
        this.input.value = el.textContent;

        this.setState({ selectedRef: el });
      }
    } else if (key === Keyboard.right || key === Keyboard.space) {
      e.preventDefault();

      this.input?.focus();
      
      this.setState({
        selectedRef: this.inputRef,
        typedValue: this.input?.value
      });
    } else if (key === Keyboard.enter) {
      this.handleSubmit();
    }
  }

  handleSuggestionClick(e) {
    e.preventDefault();
    const value = e.target.textContent;
    this.input.value = value;

    this.setState({
      typedValue: value,
      isExpanded: false
    }, () => {
      this.handleSubmit();
    });
  }

  render({ state, props } = this) {
    return (
      <section className="global-search">
        <form
          className="global-search__search-form js-global-search-form"
          data-gtm-component={props.IsGlobal ? "search-global" : "search-page"}
          action={props.SearchUrl}
          method="get"
          ref={this.formRef}
          onSubmit={() => this.handleSubmit()}
        >
          <div className="global-search__input-wrap">
            <div className='global-search__combobox'>
              <input
                className="global-search__input js-global-search-input"
                type="combobox"
                autoComplete="off"
                name="SearchText"
                id={props.IsGlobal ? "global-search" : "searchpage-search"}
                aria-live="assertive"
                aria-atomic={true}
                aria-owns="global-search-suggestions"
                aria-autocomplete="both"
                aria-expanded={state.suggestions.length > 0}
                aria-haspopup={true}
                placeholder={props.Translations.Placeholder}
                ref={this.inputRef}
                data-global={props.IsGlobal}
                aria-label="Inputfelt for søk"
                style={{
                  paddingRight: `${this.actions ? this.actions?.offsetWidth : 0}px`
                }}
                onInput={e => this.debouncedHandleInputChange(e.target?.value)}
                onKeyDown={e => this.handleInputKeyDown(e)}
              />

              <ul
                className={[
                  "global-search__suggestions",
                  "list--reset-style",
                  !state.suggestions.length && "global-search__suggestions--empty"
                ].join(" ")}
                role="listbox"
                id="global-search-suggestions"
                ref={this.suggestionsRef}
              >
                {state.suggestions.length && state.suggestions.map((suggestion, i) => (
                  <li
                    key={i + 1}
                    data-index={i + 1}
                    id={suggestion}
                    className="global-search__suggestion-item"
                    role="option"
                    onKeyDown={e => this.handleSuggestionKeyDown(e)}
                    onClick={e => this.handleSuggestionClick(e)}
                    aria-selected={e => e.target === state.selectedRef.current}
                    tabIndex={-1}
                  >
                    {suggestion}
                  </li>
                ))}
              </ul>
            </div>

            {!state.hideRobot &&
              <input
                className="global-search__robot js-global-search-robot"
                aria-hidden="true"
                name="GlobalSearchRobot"
                type="text"
                defaultValue=""
                title="robot"
                ref={this.robotRef}
              />
            }

            <div
              className="global-search__actions"
              ref={this.actionsRef}
            >
              <button
                type="reset"
                aria-label={props.Translations.Clear}
                className={[
                  "global-search__clear-btn",
                  "global-search__btn",
                  !this.input?.value && "display--none"
                ].join(" ")}
                onClick={() => {
                  this.clearList();
                  this.input.focus();
                }}
              >
                <svg
                  className="global-search__icon-clear global-search__icon"
                  viewBox="0 0 100 100"
                  preserveAspectRatio="xMidYMid meet"
                  focusable="false"
                >
                  <g transform="scale(4, 4)">
                    <path d="M9.725 11.724L.31 2.308 1.773.846l9.415 9.415L20.604.846l1.463 1.462-9.416 9.416 9.416 9.416-1.463 1.463-9.416-9.416-9.415 9.416L.31 21.14l9.415-9.416z" />
                  </g>
                </svg>
              </button>

              <button
                type="submit"
                aria-label={props.Translations.Submit}
                className={[
                  "global-search__submit-btn",
                  "global-search__btn",
                  "js-global-search-submit",
                  (!state.isInteracted && !props.IsGlobal && this.input?.value) && "display--none",
                  !this.input?.value && 'global-search__btn--empty'
                ].join(" ")}
              >
                <svg
                  viewBox="0 0 100 100"
                  preserveAspectRatio="xMidYMid meet"
                  focusable="false"
                  className="global-search__icon-search global-search__icon">
                  <g transform="scale(4, 4)">
                    <path d="M2.57 10.63c0-4.444 3.617-8.06 8.061-8.06 4.446 0 8.061 3.616 8.061 8.06 0 4.443-3.615 8.059-8.06 8.059s-8.062-3.616-8.062-8.06zM27 25.182l-8.006-8.004a10.574 10.574 0 0 0 2.269-6.55C21.263 4.76 16.503 0 10.63 0 4.761 0 0 4.758 0 10.63c0 5.87 4.76 10.629 10.631 10.629 2.472 0 4.739-.85 6.545-2.264L25.183 27 27 25.183z" />
                  </g>
                </svg>
              </button>
            </div>
          </div>

          <span
            className="global-search__live-count show-for-sr"
            aria-live="polite"
            aria-atomic="true"
          >
            {state.suggestions.length
              ? `${state.suggestions.length} ${props.Translations.Count}`
              : ""
            }
          </span>
        </form>
      </section>
    );
  }
}
